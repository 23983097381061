import { Controller } from "@hotwired/stimulus"
import WaveSurfer from "wavesurfer.js";

export default class extends Controller {
  static values = { url: String }
  static targets = [ "button" ]

  connect() {
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      hideScrollbar: true,
      barWidth: 3,
      responsive: true,
      waveColor: "#8000DE",
      height: 96,
      barRadius: 3,
      backgroundColor: "black",
      cursorWidth: 1,
      cursorColor: "#dddddd",
      backend: 'MediaElement'
      
    });
    this.wavesurfer.load(this.urlValue)
    this.wavesurfer.on("finish", () => {
      this.buttonTargets.forEach((t) => t.classList.toggle("hidden"));
    })
  }


  togglePlay({params}) {
    this.wavesurfer.playPause()

    // const isPlaying = this.wavesurfer.isPlaying()
    // console.log(this.buttonTargets)
    this.buttonTargets.forEach((t) => t.classList.toggle("hidden"));
    
  }
  
}
