import "@hotwired/turbo-rails"
import "./controllers"

document.addEventListener("turbo:load", function(event) {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-36QV96F36M');
}, false)
