import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['flash'];
  connect() {
    setTimeout(() => {      
      this.dismiss();
    }, 5000);
  }

  dismiss(event) {
    this.element.remove();
  }

}
