import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['frame'];
  static values = {
    url: String
  }

  connect() {
  }

  close(event) {
    this.element.removeAttribute("open");
  }

  trapFocus(event) {
    var isOpen = this.element.getAttribute("open")
    if (isOpen == null) {
      this.frameTarget.src = this.urlValue;
    }
  }
}
