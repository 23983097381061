import { Controller } from "@hotwired/stimulus"
import OpenPlayerJS from "openplayerjs"

export default class extends Controller {
  
  connect() {
    const player = new OpenPlayerJS('st-player');
    player.init();
  }
}
