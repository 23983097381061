import { Controller } from "@hotwired/stimulus"
import { get, post, put, patch, destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "component", "inputData", "outputData", "errorData" ]
  
  connect() {
    // console.log(this.hasComponentTarget)
  }

  toggle({ params }) {  
    
    if (this.hasComponentTarget && params.type == 'css') {
      // this.componentTarget.classList.toggle("hidden");
      this.componentTargets.forEach( (elem) => { elem.classList.toggle(params.css) } )
    }
    
    if (this.hasComponentTarget && params.type == 'tab') {
      
      this.componentTargets.forEach( (elem) => {
        var css = params.css.split(" ");
        if (css.length > 1) {
          css.map((cl) => {
            elem.classList.remove(cl);
          });
        }  else {
          elem.classList.remove(params.css)
        }
        
      } )

      this.componentTargets.forEach( (elem) => { 
        var currentTab = elem.getAttribute("data-strm-tab-param")
        if (params.tab == currentTab) {
          var css = params.css.split(" ");
          if (css.length > 1) {
            css.map((cl) => {
              elem.classList.toggle(cl);
            });
          }  else {
            elem.classList.toggle(params.css)
          }
        }
      } )

      this.inputDataTargets.forEach( (elem) => { 
        elem.classList.add("hidden")
      } )
      
      this.inputDataTargets.forEach( (elem) => { 
        var currentTab = elem.getAttribute("data-tab-name")
        // console.log(currentTab)
        if (params.tab == currentTab) {
          elem.classList.toggle("hidden")
        }
      } )

    }

    if (this.hasComponentTarget && params.type == 'modal') {
      this.element.parentElement.removeAttribute("src")
      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent click
      this.componentTarget.remove()
    }

    if (this.hasComponentTarget && params.type == 'remote') {
      
      (async () => {
        const resp = await post(params.url, { body: { id: params.channelId, selected: this.componentTarget.checked }, responseKind: "turbo-stream" })
        if (resp.ok) {
               
        }
      })();
      
    }

    if (this.hasComponentTarget && params.type == 'master') {
      
      (async () => {
        const resp = await post(params.url, { body: {}, responseKind: "turbo-stream" })
        if (resp.ok) {
               
        }
      })();
      
    }
  }

  
  
  closeWithKeyboard({params}) {
    // console.log(params)
    // if (e.code == "Escape") {
    //   this.hideModal()
    // }
  }

  showFilename() {
    // upload filename browser
    if (this.hasInputDataTarget && this.hasOutputDataTarget) {
      var filename = this.inputDataTarget.files[0].name;
      var size = this.inputDataTarget.files[0].size
      if (size > 500000 ) {
        this.inputDataTarget.value = ''
        this.errorDataTarget.classList.add('text-red-500')
        this.errorDataTarget.innerHTML = "File is too big"
      } else {
        this.outputDataTarget.value = filename;
        this.errorDataTarget.classList.remove('text-red-500')
        this.errorDataTarget.innerHTML = `Size (${this.formatBytes(size)})`
      }

      // console.log(this.inputDataTarget.files[0])
      
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  previewImage() {
    const inputUrl = document.querySelector('[data-id="input-url"]');
    const targetImg = document.querySelector('[data-id="target-img"]');
    targetImg.src = inputUrl.value
  }
}
